import lo from 'lodash';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import ajvKeywords from 'ajv-keywords';
import ajvMergePatch from 'ajv-merge-patch';
import ajvErrors from 'ajv-errors';
import authSchemas from '../config/schema/auth.json';
import userSchemas from '../config/schema/user.json';
import infoSchemas from '../config/schema/info.json';
import supportSchemas from '../config/schema/support.json';
import learningSchemas from '../config/schema/learning.json';
import subscriptionSchemas from '../config/schema/subscription.json';
import { buildData, difference, nestedMerge } from './collection';

const ajvLib = (ajvErrors(ajvKeywords(addFormats(
  new Ajv({
    allErrors: true,
    validateSchema: true,
    removeAdditional: true,
    ownProperties: true,
    logger: console,
    useDefaults: true,
    strict: true,
    strictNumbers: true,
    strictTypes: true,
    strictSchema: true,
    strictRequired: false,
    coerceTypes: true,
    code: { optimize: true },
  }),
))));

ajvMergePatch(ajvLib);

ajvLib.addKeyword({
  keyword: ['mimeType'],
  schema: true,
  errors: 'full',
  validate: (
    schema,
    data,
    // parentSchema,
    // dataCxt,
  ) => schema.includes(data.mimetype),
});

ajvLib.addKeyword({
  keyword: ['alpha'],
  schema: true,
  errors: 'full',
  validate: (
    _schema,
    data,
    // parentSchema,
    // dataCxt,
  ) => /^[a-zA-Z]+$/.test(data),
});

const schemas = [
  ...authSchemas,
  ...userSchemas,
  ...infoSchemas,
  ...supportSchemas,
  ...learningSchemas,
  ...subscriptionSchemas,
];
lo.forEach(schemas, (schema) => {
  const [name] = Object.keys(schema);
  if (!ajvLib.schemas[name]) {
    ajvLib.addSchema(schema[name]);
  }
});

export const resolver = async (schema, initial, necessary, data) => {
  try {
    let values = await schema(data);

    values = buildData(values);
    values = difference(initial, values, necessary);
    values = nestedMerge(values, 'content', 'removedContent');

    console.log('values', values);
    return { values, errors: {} };
  } catch (err) {
    const errors = lo.reduce(err.errors, (allErrors, currentError) => {
      const prop = (currentError.params.missingProperty || currentError.instancePath)
        .split('/').map((val) => (val ? `[${val}]` : val)).join('');
      allErrors[prop] = { type: currentError.keyword, message: currentError.message, root: currentError };
      return allErrors;
    }, {});
    console.warn(errors, initial, data, necessary);
    return {
      values: [],
      errors,
    };
  }
};

console.log('schemas', ajvLib.schemas);
export default ajvLib;
