import { FormControl, FormHelperText, Grid } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';

import PropTypes from 'prop-types';

import { memo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import config from '../../../../../../config/config.json';

function BodyContent({
  form, index, nested,
}) {
  const key = `${nested}[content][${index}][text]`;
  const editorRef = useRef(null);
  return (
    <Controller
      control={form.control}
      name={key}
      render={({ fieldState, field }) => {
        return (
          <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
            <Editor
              apiKey={config.editor.token}
              init={{
                ...config.editor.init,
                toolbar: config.editor.init.bodyToolbar,
                isRequired: true,
                height: 0,
                // skin: 'material-classic',
              }}
              tinymceScriptSrc="https://cdn.tiny.cloud/1/umii965on1quohs2kslnn67v9nkj2m43wal0ypkc5irfqi04/tinymce/6.8.2-45/tinymce.min.js"
              scriptLoading={{ async: true }}
              initialValue={field.value}
              onInit={(props, editor) => {
                editorRef.current = editor;
              }}
              onBlur={() => {
                field.onChange(editorRef.current.getContent());
              }}
              onChange={(props) => {
                // eslint-disable-next-line react/prop-types
                if (props.type === 'change' && props.originalEvent?.type === 'blur') {
                  field.onChange(editorRef.current.getContent());
                }
              }}
            />
            <FormHelperText color="error">{fieldState.error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

BodyContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
};
BodyContent.defaultProps = {};

export default memo(BodyContent);
