import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (subTopicUuid, options) => {
  const query = {
    subTopicUuid,
    attributeMeta: {
      exclude: DEFAULT_EXCLUDE,
    },
    filterMeta: { parentUuid: { is: 'null' } },
    includeMeta: [
      {
        association: 'limit',
        on: {
          subTopicUuid,
          literal: '"SubTopicContent".uuid = "limit"."subTopicContentUuid"',
        },
      },
      {
        association: 'content',
        separate: true,
        include: [
          {
            association: 'content',
            separate: true,
            include: [
              {
                association: 'content',
                separate: true,
                include: [
                  {
                    association: 'cases',
                    duplicating: true,
                    dInclude: [
                      {
                        association: 'subTopicLimit',
                      },
                    ],
                  },
                  {
                    association: 'statutes',
                    duplicating: true,
                    dInclude: [
                      {
                        association: 'subTopicLimit',
                      },
                    ],
                  },
                ],
              },
              {
                association: 'cases',
                duplicating: true,
                dInclude: [
                  {
                    association: 'subTopicLimit',
                  },
                ],
              },
              {
                association: 'statutes',
                duplicating: true,
                dInclude: [
                  {
                    association: 'subTopicLimit',
                  },
                ],
              },
            ],
          },
          {
            association: 'cases',
            duplicating: true,
            dInclude: [
              {
                association: 'subTopicLimit',
              },
            ],
          },
          {
            association: 'statutes',
            duplicating: true,
            dInclude: [
              {
                association: 'subTopicLimit',
              },
            ],
          },
        ],
      },
      {
        association: 'cases',
        duplicating: true,
      },
      {
        association: 'statutes',
        duplicating: true,
      },
    ],
    caseFilter: {
      filterMeta: { contentUuid: { is: 'null' } },
      includeMeta: [
        {
          association: 'subTopicLimit',
        },
      ],
    },
    statuteFilter: {
      filterMeta: { contentUuid: { is: 'null' } },
      includeMeta: [
        {
          association: 'content',
        },
        {
          association: 'subTopicLimit',
        },
      ],
    },
  };
  const res = await fetch(
    `${config.api.url}${config.endpoints.learning}sub-topic-content?${queryString.stringify(query)}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization(),
      },
    },
  );
  const data = await res.json();

  const resp = responseHandler(data, 'Get Sub-Topic content:');
  if (options?.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useSubTopicContent = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['SubTopicContent', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
export const getSubTopicContent = queryFn;
