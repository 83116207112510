import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { upperFirst } from 'lodash/string';
import queryString from 'qs';
import { useState } from 'react';
import {
  Link, useLocation, Outlet, useSearchParams,
} from 'react-router-dom';
import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Box, Stack, Breadcrumbs, Typography, Button,
} from '@mui/material';
import { blue } from '@mui/material/colors';

import { MODULES } from '../../../constants/route';

import Show from '../../../components/Show/Show';
import Select from '../../../components/Select/Select';
import { useModuleList } from '../../../services/learning/module/getModules';
import { makeQueryMeta } from '../../../utils/requestBuilder';
import styles from './ModulesStyles';

export default function Modules() {
  const { pathname } = useLocation();
  const pathList = pathname.split('/');
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeModule, setActiveModule] = useState(null);
  const [modules, setModules] = useState([]);

  const TOGGLE_BUTTONS = _.values(MODULES.ROUTES);

  const queryMeta = makeQueryMeta(1);
  const jurisdictionUuid = searchParams.get('jurisdiction');
  const moduleUuid = searchParams.get('module');
  const makeQuery = () => {
    queryMeta.order = { sequence: 'ASC' };
    return {
      queryMeta,
      filterMeta: { jurisdictionUuid },
    };
  };
  const moduleList = useModuleList(makeQuery(), {
    enabled: !!jurisdictionUuid,
    key: 'for-select',
    onSuccess: ({ data }) => {
      if (!activeModule || !moduleUuid) {
        setModules(data);
        const module = moduleUuid ? data.find((val) => val.uuid === moduleUuid) : data[0];
        if (!moduleUuid) {
          searchParams.set('module', module.uuid);
        }
        setActiveModule(moduleUuid ? data.find((val) => val.uuid === moduleUuid) : data[0]);
      }
    },
  });

  const changeCurrentModule = (module) => {
    searchParams.set('module', module.uuid);
    setSearchParams(searchParams);
    setActiveModule(module);
  };

  const query = queryString.stringify({
    ...(jurisdictionUuid ? { jurisdiction: jurisdictionUuid } : {}),
    ...((moduleUuid || activeModule?.uuid) ? { module: moduleUuid || activeModule?.uuid } : {}),
  });

  return (
    <>
      <Box sx={styles.wrapper}>
        <Show show={activeModule && pathname !== `/${MODULES.ROUTE}`}>
          <Stack direction="row" alignItems="center">
            <Select
              label="Modules"
              loaded={moduleList?.isFetched}
              noDataText="There are any modules yet"
              activeValue={activeModule}
              setValue={(e) => changeCurrentModule(e.target.value)}
              itemNameGetter={(value) => value.name}
              scrolledBottom={moduleList.refetch}
              values={modules}
              sizeWidth="middle"
            />
            <Breadcrumbs sx={styles.breadcrumbs}>
              {pathList.length > 1 && pathList.map((path, i, arr) => {
                return (
                  i + 1 < pathList.length
                    ? (
                      <Link
                        to={`${arr.slice(0, i + 1).join('/')}?${query}`}
                        replace
                        style={{ color: blue[600] }}
                        key={path}
                      >
                        {upperFirst(path)}
                      </Link>
                    )
                    : (
                      <Typography key={path}>
                        {upperFirst(path)}
                      </Typography>
                    )
                );
              })}
            </Breadcrumbs>
          </Stack>
        </Show>
      </Box>
      <br />
      <Box component="nav" sx={{ flexGrow: 1 }}>
        <List role="menubar" orientation="horizontal" style={styles.list}>
          {(
            TOGGLE_BUTTONS.map(({ NAME, ROUTE }) => {
              const to = `/${MODULES.ROUTE}/${ROUTE}`;
              const active = pathname.includes(to);
              return (
                <ListItem key={`li:${NAME}:${ROUTE}`} style={active ? styles.activeSwitcher : {}}>
                  <Link to={`${to}?${query}`}>
                    <Button style={active ? styles.colorWhite : styles.colorBlack}>{NAME}</Button>
                  </Link>
                </ListItem>
              );
            })
          )}
        </List>
      </Box>
      <br />
      {/* We hide Outlet in case there are no picked active module, but keep */}
      {/* opportunity to create new modules at relevant page */}
      <Show show={moduleList.isFetched}>
        <DndProvider backend={HTML5Backend}>
          <Outlet />
        </DndProvider>
      </Show>
    </>
  );
}
